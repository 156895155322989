* {
    box-sizing: border-box;
}

.html, body, #root {
    width: 100vw;
    height: 90vh;
    margin: 0;
    padding: 0;
    text-align: center;
    img {
        vertical-align: middle;
    }
}


button {
  display: block;
  position: relative;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 30px;
  font-size: .75em;
  letter-spacing: .35em;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid hsla(0,0%,100%,.2);
  transition: all .2s ease;
  margin: 0 auto;
  margin-top: 10px;

  &:before, 
  &:after {
    content: 'E N T E R';
    position: absolute;
      top: 0;
      left: 0;
    padding: 8px 0;
    width: 100%;
    -webkit-clip: rect(0px, 0px, 0px, 0px);
    clip: rect(0px, 0px, 0px, 0px);
    background: #ee6c4d;
    color: #000;
  }
  
  &:before {
    left: -3px;
    top: -2px;
    text-shadow: 2px 0 #fff;
    box-shadow: 2px 0 #fff;
  }

  &:after {
    left: 2px;
    bottom: -2px;
    text-shadow: -1px 0 #fff;
    box-shadow: -1px 0 #fff;
  }

  &:hover {
    background: white;
    color: black;
  }

  &:hover:before {
      animation: glitch-test 1.5s infinite linear alternate-reverse;
  }
  
  &:hover:after {
      animation: glitch-test 2s infinite linear alternate;
  }

}

@keyframes glitch-test {
0% {
  clip: rect(-3px, 600px, 0px, 0px);
}
5.88235% {
  clip: rect(0px, 600px, 0px, 0px);
}
11.76471% {
  clip: rect(-3px, 600px, 0px, 0px);
}
17.64706% {
  clip: rect(-3px, 600px, 0px, 0px);
}
23.52941% {
  clip: rect(100px, 600px, 100px, 0px);
}
29.41176% {
  clip: rect(0px, 600px, 600px, 0px);
}
35.29412% {
  clip: rect(100px, 600px, 0px, 0px);
}
41.17647% {
  clip: rect(0px, 600px, 600px, 0px);
}
47.05882% {
  clip: rect(100px, 600px, 0px, 0px);
}
52.94118% {
  clip: rect(-3px, 600px, 0px, 0px);
}
58.82353% {
  clip: rect(100px, 450px,100px, 0px);
}
64.70588% {
  clip: rect(0px, 450px, 0px, 0px);
}
70.58824% {
  clip: rect(100px, 450px, 100px, 0px);
}
76.47059% {
  clip: rect(0px, 450px, 0px, 0px);
}
82.35294% {
  clip: rect(0px, 450px, 0px, 0px);
}
88.23529% {
  clip: rect(0px, 450px, 0px, 0px);
}
94.11765% {
  clip: rect(0px, 450px, 0px, 0px);
}
100% {
  clip: rect(0px, 450px, 0px, 0px);
}
}

.glitch {
  text-align: center;
  color:black;
  font-size:80px;
  position:relative;
  white-space: nowrap;
  width: 400px;
  margin:0 auto;
}

.glitch::before,
.glitch::after {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate-reverse;
  overflow: hidden;
  position: absolute;
  top: 0;
  clip: rect(0, 900px, 0, 0);
  content: attr(data-text);
}

.glitch::after {
  animation-name: glitch-animation;
  animation-duration: 4s;
  left: 4px;
  text-shadow: -1px 0 #ffa800;
  background: white;
}

.glitch::before {
  animation-name: glitch-animation-2;
  animation-duration: 6s;
  left: -4px;
  text-shadow: 1px 0 #00d8ff;
  background: white;
}

@mixin rect($top, $bottom) {
  clip: rect($top, 9999px, $bottom, 0);
}

/* Expanded Animations */
@keyframes glitch-animation {
  0% { @include rect(42px, 44px); }
  5% { @include rect(12px, 59px); }
  10% { @include rect(48px, 29px); }
  15.0% { @include rect(42px, 73px); }
  20% { @include rect(63px, 27px); }
  25% { @include rect(34px, 55px); }
  30.0% { @include rect(86px, 73px); }
  35% { @include rect(20px, 20px); }
  40% { @include rect(26px, 60px); }
  45% { @include rect(25px, 66px); }
  50% { @include rect(57px, 98px); }
  55.0% { @include rect(5px, 46px); }
  60.0% { @include rect(82px, 31px); }
  65% { @include rect(54px, 27px); }
  70% { @include rect(28px, 99px); }
  75% { @include rect(45px, 69px); }
  80% { @include rect(23px, 85px); }
  85.0% { @include rect(54px, 84px); }
  90% { @include rect(45px, 47px); }
  95% { @include rect(37px, 20px); }
  100% { @include rect(4px, 91px); }
}

@keyframes glitch-animation-2 {
  0% { @include rect(65px, 100px); }
  5% { @include rect(52px, 74px); }
  10% { @include rect(79px, 85px); }
  15.0% { @include rect(75px, 5px); }
  20% { @include rect(67px, 61px); }
  25% { @include rect(14px, 79px); }
  30.0% { @include rect(1px, 66px); }
  35% { @include rect(86px, 30px); }
  40% { @include rect(23px, 98px); }
  45% { @include rect(85px, 72px); }
  50% { @include rect(71px, 75px); }
  55.0% { @include rect(2px, 48px); }
  60.0% { @include rect(30px, 16px); }
  65% { @include rect(59px, 50px); }
  70% { @include rect(41px, 62px); }
  75% { @include rect(2px, 82px); }
  80% { @include rect(47px, 73px); }
  85.0% { @include rect(3px, 27px); }
  90% { @include rect(26px, 55px); }
  95% { @include rect(42px, 97px); }
  100% { @include rect(38px, 49px); }
}

.logo {
    padding-top: 1rem;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.04rem;
}

.loading {
    margin-top: 15%;
    transform: scale(0.7);
    animation-name: done;
    animation-duration: 3s;
}

@keyframes done {
  from {opacity: 1;}
  to {opacity: 0;}
}

canvas {
    width: 100%;
    height: 100%;
}

.content {
    padding: 2rem;
    transform: translate3d(20%, -10%, 0);
    text-align: left;
    background: #202035;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    background: #202035;
    color: white;
    border-radius: 5px;
    width: 40%;
    text-align: left;
    height: auto;
    top:50%;
    padding: 2rem;
    left:50%;
    transform: translate(-50%, 0);
    tr {
        cursor: pointer;
        &:hover{
            color: #ee6c4d;
        }
    }
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }